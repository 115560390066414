<template>
  <div class="page_wrap">
    <img
      class="qrCode"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/LeaseTransMaster-mini/qrCode.png"
    />
    <div class="title">扫码归还设备</div>
    <div class="tip">请扫描归还箱中的设备条形码，进行归还确认操作</div>
    <div class="scan-button" @click="openScan">
      <img
        class="scan-icon"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LeaseTransMaster-mini/scan_icon.png"
      />
      <span>扫一扫归还</span>
    </div>
    <div class="scan" v-if="showScan">
      <div class="video-container">
        <div class="scan-box"></div>
        <video class="video" id="video-1"></video>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BrowserMultiFormatReader,
  ChecksumException,
  FormatException,
} from "@zxing/library";
import { scanToReturnAPI } from "@/api/leaseTransMaster";
export default {
  data() {
    return {
      codeReader: new BrowserMultiFormatReader(),
      videoInputDevices: [],
      currentVideoInputDevice: {},
      decodeResult: undefined,
      showScan: false,
    };
  },
  watch: {
    currentVideoInputDevice: function () {
      this.decodeFromInputVideo();
    },
    decodeResult: function () {
      this.successDecode();
    },
  },
  unmounted() {
    this.codeReader.reset(); //关闭摄像头
  },
  methods: {
    async openScan() {
      await navigator.mediaDevices.getUserMedia({ video: true });
      this.showScan = true;
      const _this = this;
      _this.codeReader
        .getVideoInputDevices() //老版本listVideoInputDevices()
        .then((videoInputDevices) => {
          if (videoInputDevices && videoInputDevices.length) {
            if (videoInputDevices.length > 1) {
              videoInputDevices.reverse();
            } //防止先唤出前摄像头
            _this.videoInputDevices = videoInputDevices;
            _this.currentVideoInputDevice = videoInputDevices[0];
          }
        })
        .catch(() => {});
    },

    decodeFromInputVideo() {
      const _this = this;
      _this.codeReader.reset();
      // 多次
      _this.codeReader.decodeFromVideoDevice(
        _this.currentVideoInputDevice.deviceId,
        "video-1",
        (result, err) => {
          if (result) {
            _this.decodeResult = result;
          }
          if (err) {
            if (err instanceof ChecksumException) {
              _this.$dialog.alert({ message: "扫码无效" });
            }
            if (err instanceof FormatException) {
              _this.$dialog.alert({ message: "扫码无效" });
            }
          }
        }
      );
    },
    successDecode() {
      this.showScan = false;
      this.codeReader.reset();
      this.$dialog
        .confirm({
          title: "提示",
          message: `确定要归还编号为${this.decodeResult.text}的设备吗？`,
          confirmButtonColor: "#F89350",
          cancelButtonColor: "#666",
        })
        .then(() => {
          return scanToReturnAPI({
            machine_id: this.decodeResult.text,
          });
        })
        .then((res) => {
          if (res.code == 0) {
            this.$toast.success("设备归还成功");
          } else {
            this.$dialog.alert({
              title: "错误",
              message: res.message,
              confirmButtonColor: "#F89350",
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.page_wrap {
  background: #fff3e3;
  min-height: 100vh;
  padding-top: 102px;
  .qrCode {
    width: 401px;
    display: block;
    margin: auto;
  }
  .title {
    font-weight: 600;
    font-size: 40px;
    color: #333333;
    line-height: 56px;
    text-align: center;
    margin-top: -40px;
  }
  .tip {
    font-size: 24px;
    color: #999999;
    line-height: 33px;
    text-align: center;
    margin-top: 16px;
  }
  .scan-button {
    margin: 178px auto 0;
    width: 650px;
    height: 88px;
    background: linear-gradient(180deg, #fa9957 0%, #e35415 100%);
    border-radius: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 32px;
    color: #fff8dd;
    line-height: 45px;
    .scan-icon {
      width: 35px;
      height: 33px;
      margin-right: 23px;
    }
  }
  .qrcode-stream {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }
}
.scan {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  color: #fff;
}

.video {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  border-radius: 10px;
}

.scan-box {
  position: absolute;
  top: 0;
  left: 16%;
  width: 70%;
  height: 2px;
  box-sizing: border-box;
  background: linear-gradient(
    to left,
    #0074d9 0%,
    #0074d9 20%,
    transparent 40%,
    transparent 60%,
    #0074d9 80%,
    #0074d9 100%
  );
  animation: scanBoxAnimation 2s infinite;
}

@keyframes scanBoxAnimation {
  0% {
    transform: translateY(15vh);
  }
  50% {
    transform: translateY(70vh);
  }
  100% {
    transform: translateY(15vh);
  }
}
</style>
