import request from "@/utils/request";
const test = location.origin.indexOf("test-huanxinxiang.qiumo.net.cn") >= 0;
// http://121.40.117.53:11145
const ipBaseURL = test ? 'https://test-huanxinxiang.qiumo.net.cn' : "https://zyt.tie-xin.com.cn"
// const ipBaseURL = "https://zyt.tie-xin.com.cn";

// 预定获取验证码
export function getReserveCodeAPI(data) {
  return request({
    url: "/zuyitong-server/v1/reserve/get_reserve_code/",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
  });
}

// 预定时校验验证码
export function checkReserveCodeAPI(data) {
  return request({
    url: "/zuyitong-server/v1/reserve/check_reserve_code/",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
  });
}

// 扫一扫归还
export function scanToReturnAPI(data) {
  return request({
    url: "/zuyitong-server/v1/staff_page/scan_to_return/",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
  });
}
